<template>
  <div>
    <VRow>
      <VCol
        data-test-label="upload-image-item-question"
        cols="12"
      >
        {{ meta.question }}
      </VCol>
    </VRow>
    <ItemButtons
      v-if="!isSkipped || canBeChange"
      :disabled="disabled"
      :loading="loading"
      :can-be-skipped="canBeSkipped"
      :skip-button-show="!isSkipped && !isItemCompleted"
      :submit-button-text="submitText"
      :submit-button-color="submitButtonColor"
      :submit-button-show="!isSkipped || canBeChange"
      :data-test-submit="'upload-image-item-btn-' + (canBeChange ? 'reselect': 'select')"
      data-test-skip="upload-image-item-btn-skip"
      @skip="skip"
      @submit="selectImage"
    />
    <div
      v-if="error"
      class="mt-2 tt-text-body-2 tt-light-red--text"
      data-test="upload-image-error"
    >
      {{ error }}
    </div>
    <VFileInput
      ref="imageInput"
      v-model="image"
      type="file"
      :accept="$options.ACCEPTED_IMAGE_TYPE"
      class="d-none"
      data-test="upload-image-item-file-input"
    />
  </div>
</template>

<script>
import ItemButtons from '@/components/items/ItemButtons.vue';

import getImageRect from '@/helpers/getImageRect';
import toBase64 from '@/helpers/toBase64';
import getValidators from '@/helpers/getValidators';

import { ACCEPTED_IMAGE_TYPE } from './constants';

export default {
  name: 'UploadImageItem',
  components: { ItemButtons },
  ACCEPTED_IMAGE_TYPE,
  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: null,
    },
    taskCompleted: {
      type: Boolean,
      default: false,
    },
    isItemCompleted: {
      type: Boolean,
      default: false,
    },
    isSkipped: {
      type: Boolean,
      default: false,
    },
    canBeSkipped: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image: null,
      error: null,
      base64: null,
    };
  },
  computed: {
    canBeChange() {
      return !!this.meta?.file?.url && !this.taskCompleted;
    },
    rules() {
      const { validate } = this.meta;
      if (validate) {
        return getValidators(validate);
      }
      return [];
    },
    submitText() {
      return this.canBeChange ? this.$t('items.add_photo.change_photo') : this.$t('items.add_photo.submit');
    },
    submitButtonColor() {
      return this.canBeChange ? 'tt-secondary' : 'tt-primary';
    },
  },
  watch: {
    async image() {
      if (this.image) {
        this.error = await this.setError();
        if (!this.error) {
          this.base64 = await toBase64(this.image);
          this.$emit('submit', {
            content_base64: this.base64.split(',')[1],
            fileId: this.image.lastModified,
            name: this.image.name,
          });
        } else {
          this.image = null;
        }
      }
    },
    errors(val) {
      this.image = null;
      let errorText = 'Неизвестная ошибка';
      if (val.data.length) {
        const text = val.data.find((e) => e.key && e.key === 'description' && e.value);
        if (text) {
          errorText = text.value;
        }
      }
      this.error = errorText;
    },
  },
  methods: {
    skip() {
      this.image = null;
      this.error = null;
      this.$emit('skip');
    },
    selectImage() {
      this.$refs.imageInput.$refs.input.click();
    },
    async setError() {
      if (typeof this.image === 'undefined' && !this.canBeSkipped) {
        return this.$t('validators.file_upload_required');
      }
      if (this.image) {
        const types = ACCEPTED_IMAGE_TYPE.split(',');
        if (!types.includes(this.image?.type)) {
          return this.$t('validators.file_upload_required');
        }
        if (this.rules.length) {
          try {
            this.base64 = await toBase64(this.image);
            const size = await getImageRect(this.base64);
            return this.rules.map((validation) => {
              if (typeof validation === 'string') return validation;
              const validationResult = validation(size);
              return typeof validationResult === 'string' ? validationResult : '';
            }).find((message) => message !== '');
          } catch (e) {
            return this.$t('validators.file_upload_required');
          }
        }
      }
      return null;
    },
  },
};
</script>
