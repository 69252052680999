<template>
  <div>
    <VRow>
      <VCol
        cols="12"
        class="text-pre-line"
        data-test="text-item-question"
      >
        {{ meta.question }}
      </VCol>
    </VRow>
    <ItemButtons
      v-if="!isSkipped && canBeSkipped && !isItemCompleted"
      :can-be-skipped="canBeSkipped"
      data-test-submit="text-item-btn-submit"
      data-test-skip="text-item-btn-skip"
      @skip="skip"
    />
  </div>
</template>

<script>
import ItemButtons from '@/components/items/ItemButtons.vue';

export default {
  name: 'TextItem',
  components: { ItemButtons },
  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
    isSkipped: {
      type: Boolean,
      default: false,
    },
    canBeSkipped: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isItemCompleted: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    skip() {
      this.$emit('skip');
    },
  },
};
</script>
