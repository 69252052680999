<template>
  <VRow class="my-0">
    <VSpacer v-if="isUser" />
    <VCol
      :cols="12"
      :sm="$options.CHAT_COLS"
      :md="$options.CHAT_COLS"
      :lg="$options.CHAT_COLS"
      :xl="$options.CHAT_COLS"
      class="py-0"
    >
      <div class="d-flex">
        <TTAvatar
          v-if="!isUser"
          class="align-self-end"
          :src="botAvatar"
          data-test-label="chat-group-bot-avatar"
        />
        <div class="ml-3 chat-bubble__wrapper">
          <template v-for="message in messages">
            <div
              v-if="isUser || !!message.firstShown.trim()"
              :key="message.id"
              class="tt-text-body-1 chat-bubble__item mt-5"
              :class="[
                classesList,
                {'user-messages__image' : isUser && (message.type === $options.ALLOWED_CHAT_TYPE.ADD_PHOTO) }
              ]"
            >
              <div :class="!isUser || (message.type !== $options.ALLOWED_CHAT_TYPE.ADD_PHOTO)? 'ma-5' : ''">
                <div
                  v-if="!isUser"
                  class="mb-2 tt-text-body-2 tt-light-mono-64--text"
                  data-test="chat-group-bot-name"
                >
                  {{ botName }}
                </div>
                <ImageChatItem
                  v-if="isUser && (message.type === $options.ALLOWED_CHAT_TYPE.ADD_PHOTO)"
                  :meta="message.meta"
                  :result="message.result"
                  :data-test="'chat-group-image-chat-item' + (!isUser ? '-bot' : '')"
                />
                <HTMLItem
                  v-else-if="message.type === $options.ALLOWED_CHAT_TYPE.HTML"
                  :meta="message.meta"
                  :data-test="'chat-group-html-item' + (!isUser ? '-bot' : '')"
                />
                <TextItem
                  v-else-if="message.type === $options.ALLOWED_CHAT_TYPE.ENTER_STRING"
                  :disabled="!!loading"
                  :can-be-skipped="message.canBeSkipped"
                  :is-item-completed="message.isCompleted"
                  :is-skipped="message.isSkipped"
                  :meta="message.meta"
                  :data-test="'chat-group-text-item' + (!isUser ? '-bot' : '')"
                  @skip="skip(message.id)"
                  @submit="submit($event, message)"
                />
                <UploadImageItem
                  v-else-if="!isUser && (message.type === $options.ALLOWED_CHAT_TYPE.ADD_PHOTO)"
                  :meta="message.meta"
                  :errors="errors[message.id] ? errors[message.id] : null"
                  :disabled="loading && loading!==message.id"
                  :loading="loading && loading===message.id"
                  :task-completed="message.taskCompleted"
                  :is-item-completed="message.isCompleted"
                  :can-be-skipped="message.canBeSkipped"
                  :is-skipped="message.isSkipped"
                  :data-test="'chat-group-upload-image-item' + (!isUser ? '-bot' : '')"
                  @skip="skip(message.id)"
                  @submit="submit($event, message)"
                />
                <RateItem
                  v-else-if="message.type === $options.ALLOWED_CHAT_TYPE.RATE"
                  :meta="message.meta"
                  :result="message.result"
                  :disabled="loading && loading!==message.id"
                  :loading="loading && loading===message.id"
                  :can-be-skipped="message.canBeSkipped"
                  :is-skipped="message.isSkipped"
                  is-chat
                  :data-test="'chat-group-rate-item' + (!isUser ? '-bot' : '')"
                  @submit="submit($event, message)"
                  @skip="skip(message.id)"
                />
              </div>
            </div>
          </template>
          <div
            v-if="!isUser && typing"
            class="mt-4"
          >
            <div
              data-test="chat-group-bot-name-typing"
              class="tt-text-body-1"
            >
              {{ botName }}
            </div>
            <div
              data-test="chat-group-bot-typing"
              class="d-flex align-center tt-text-body-2 tt-light-mono-46--text"
            >
              <VImg
                class="flex-grow-0"
                contain
                width="20"
                height="20"
                :src="require('@/assets/dots.svg')"
                data-test="chat-group-bot-typing-icon"
              />
              <span
                data-test="chat-group-bot-typing-text"
                class="ml-1"
              >{{ $t('common.typing') }}</span>
            </div>
          </div>
        </div>
      </div>
    </VCol>
  </VRow>
</template>

<script>
import { CHAT_COLS, BOT_NAME, TYPE_DELAY } from '@/components/chat/constans';
import { ALLOWED_CHAT_TYPE } from '@/helpers/constants';

import HTMLItem from '@/components/items/HTMLItem.vue';
import TextItem from '@/components/items/TextItem.vue';
import RateItem from '@/components/items/RateItem.vue';
import UploadImageItem from '@/components/items/UploadImageItem.vue';
import ImageChatItem from '@/components/items/ImageChatItem.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ChatGroup',
  components: {
    HTMLItem,
    TextItem,
    RateItem,
    UploadImageItem,
    ImageChatItem,
  },
  props: {
    isUser: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: Array,
      required: true,
    },
    loading: {
      type: Number,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  CHAT_COLS,
  ALLOWED_CHAT_TYPE,
  data() {
    return {
      typing: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['bot']),
    classesList() {
      if (this.isUser) {
        return ['tt-light-blue', 'tt-light-mono-0--text', 'user-messages'];
      }
      return ['tt-light-mono-4', 'tt-light-mono-100--text', 'bot-messages'];
    },
    botName() {
      return this.bot.botName || BOT_NAME;
    },
    botAvatar() {
      return this.bot.botIconUrl;
    },
    lastMessage() {
      if (this.messages) {
        return this.messages[this.messages.length - 1];
      }
      return null;
    },
  },
  watch: {
    messages: {
      handler: 'toggleTyping',
      immediate: true,
    },
  },
  methods: {
    toggleTyping() {
      if (!this.isUser && !this.lastMessage?.firstShown.trim()) {
        this.typing = true;
        setTimeout(() => {
          this.typing = false;
          this.lastMessage.firstShown = this.$dayjs().toString();
          this.$emit('showed', this.lastMessage);
        }, TYPE_DELAY);
      }
    },
    skip(id) {
      this.$emit('skip', id);
    },
    submit(answer, message) {
      this.$emit('submit', { answer, message });
    },
  },
};
</script>

<style lang="scss" scoped>
/* hot-fix */
::v-deep .tt-avatar{
  min-height: 44px;
  max-height: 44px;
  min-width: 44px;
  max-width: 44px;
}
.chat-bubble__wrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  word-break: break-word;
}

.chat-bubble__item, ::v-deep .image-chat-item{
  border-radius: 12px;
  position: relative;
}
.chat-bubble__wrapper> .bot-messages:last-child{
  &:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid;
    left: -0.35rem;
  }
  &:after {
    border-bottom-right-radius: 0.5rem;
    left: 20px;
  }
}
.chat-bubble__wrapper> .user-messages:not(.user-messages__image):last-child{
  &:before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid;
    right: -0.35rem;
  }
  &:after {
    border-bottom-left-radius: 0.5rem;
    right: -40px;
  }
}
.chat-bubble__wrapper > .chat-bubble__item:not(.user-messages__image):last-child{
  &:before {
    transform: translate(0, -0.1rem);
    border-color: inherit;
  }
  &:after {
    background-color: #fff;
    transform:translate(-30px, -2px);
    width: 10px;
  }
  &:before,
  &:after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }
}

</style>
