<template>
  <VImg
    class="image-chat-item"
    :aspect-ratio="aspectRatio"
    :src="meta.url"
    data-test-label="image-chat-item-img"
  />
</template>

<script>
import getImageRect from '@/helpers/getImageRect';
import {
  IMAGE_HORIZONTAL_ASPECT, IMAGE_VERTICAL_ASPECT, IMAGE_MAX_SQUARE_ASPECT, IMAGE_MIN_SQUARE_ASPECT,
} from '@/components/chat/constans';

export default {
  name: 'ImageChatItem',
  props: {
    /**
     * @type {{
     * url: string,
     * width?: number,
     * height?: number,
     * }}
     */
    meta: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      aspectRatio: 1,
    };
  },
  watch: {
    'meta.url': {
      immediate: true,
      async handler() {
        let { width, height } = this.meta;
        if (!width && !height) {
          const rect = await getImageRect(this.meta.url);
          width = rect.width;
          height = rect.height;
        }

        const originalAspect = width / height;
        if (originalAspect > IMAGE_MIN_SQUARE_ASPECT && originalAspect < IMAGE_MAX_SQUARE_ASPECT) {
          this.aspectRatio = 1;
        } else if (width < height) {
          this.aspectRatio = IMAGE_VERTICAL_ASPECT;
        } else if (width > height) {
          this.aspectRatio = IMAGE_HORIZONTAL_ASPECT;
        }
      },
    },
  },
};
</script>
