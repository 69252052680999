<template>
  <TTTextarea
    ref="textarea"
    :value="value"
    :maxlength="false"
    x-small
    auto-grow
    :placeholder="$t('placeholders.chat')"
    class="chat-textarea"
    v-bind="$props"
    data-test-label="chat-textarea"
    @input="emmitInput"
    @update:error="error=$event"
  >
    <template #append>
      <TTBtn
        icon
        class="chat-textarea-icon"
        :disabled="error || !value"
        @click="submit"
      >
        <VIcon
          color="tt-light-mono-24"
          :size="22"
        >
          fab fa-telegram-plane
        </VIcon>
      </TTBtn>
    </template>
    <!-- eslint-disable vue/no-v-html -->
    <template #message="{ message }">
      <span
        data-test="chat-textarea-error"
        v-html="message"
      />
    </template>
    <!--eslint-enable-->
  </TTTextarea>
</template>

<script>
export default {
  name: 'ChatTextarea',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: false,
    };
  },

  methods: {
    setError(value) {
      this.$emit('error', value);
    },
    focus() {
      this.$refs.textarea.$children[0].focus();
    },
    emmitInput($event) {
      if (!this.$refs.textarea.$children[0].valid) {
        this.error = true;
      }
      this.$emit('input', $event);
    },
    submit() {
      if (!this.error) {
        this.$emit('submit', this.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .chat-textarea {
    max-width: 1128px;
    display: flex;
    flex: 1 1 auto;
    border-top: 1px solid map-get($tt-light-mono-8, 'base');
    padding: 20px 0;

    ::v-deep {
      .v-input {
        .v-progress-linear{
          top: calc(100% - 2px);
        }
        &__append-inner {
          height: 44px;
          position: sticky;
          top: 176px;
          right: 7px;

          i {
            color: map-get($tt-light-blue, 'base') !important;
          }
        }
        &__control {
          min-height: 44px;
          padding-bottom: 12px;
        }

        &__slot {
          min-height: 44px;
          max-height: 220px;
          overflow: auto;
          align-items: normal;
        }

        .v-text-field__slot {
          min-height: auto;
        }

        textarea {
          min-height: auto;
        }
      }
    }
  }
</style>
