<template>
  <VContainer
    ref="Container"
    :class="['chat-container', 'pa-0', $vuetify.breakpoint.xsOnly? 'chat-mobile': '']"
  >
    <div class="chat-content pt-3 pb-6 px-3 ">
      <template v-if="chatMessages">
        <ChatRow
          v-for="{isUser, id, messages} in chatMessages"
          :key="id"
          :is-user="isUser"
          :messages="messages"
          :loading="loading"
          :errors="errors"
          @showed="showed"
          @skip="skip"
          @submit="submit"
        />
      </template>
      <VRow
        id="chat-end"
        ref="chatEnd"
        class="ml-n2"
      >
        <VCol
          v-if="chatCanBeCompleted"
          class="pl-16"
          :cols="$vuetify.breakpoint.xsOnly ? 12 : $options.CHAT_COLS"
        >
          <TTBtn
            block
            large
            :loading="startBtnLoading"
            data-test-label="chat-btn-start"
            @click="completeChat"
          >
            {{ $t('common.start') }}
          </TTBtn>
        </VCol>
      </VRow>
    </div>

    <div :class="['chat-input']">
      <ChatTextarea
        ref="chatTextarea"
        v-model.trim="answer"
        :loading="loadingInput"
        :disabled="disableInput"
        :rules="rulesInput"
        @submit="submit({answer : $event})"
      />
    </div>
  </VContainer>
</template>

<script>
import {
  ADD_PHOTO, ALLOWED_CHAT_TYPE, ENTER_STRING, TASK,
} from '@/helpers/constants';
import { MERGE_LEVELS } from '@/plugins/vuex/mutationTypes';
import { CHAT_COLS } from '@/components/chat/constans';

import * as snamiApi from '@/services/api/snami';

import getResultRequest from '@/helpers/getResultRequest';
import getValidators from '@/helpers/getValidators';
import { generateFlatItem } from '@/helpers/generateFlatItem';

import ChatTextarea from '@/components/chat/ChatTextarea.vue';
import ChatRow from '@/components/chat/ChatGroup.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SET_LEVEL_LIST_ACTION, SKIP_ITEM_ACTION } from '@/plugins/vuex/actionTypes';

export default {
  name: 'ChatView',

  components: {
    ChatTextarea,
    ChatRow,
  },

  inject: ['Names'],

  data() {
    return {
      answer: '',
      loading: null,
      startBtnLoading: false,
      errors: [],
    };
  },

  CHAT_COLS,
  computed: {
    ...mapGetters('levels', ['skippedTaskItem', 'chat']),

    lastMessage() {
      if (this.chatMessages && this.chatMessages.length) {
        const lastMessagesRow = this.chatMessages[this.chatMessages.length - 1].messages;
        return lastMessagesRow[lastMessagesRow.length - 1];
      }
      return {};
    },

    disableInput() {
      return !this.lastMessage?.firstShown.trim()
        || this.lastMessage?.type !== ENTER_STRING
        || this.loadingInput;
    },

    rulesInput() {
      if (this.lastMessage?.type === ENTER_STRING) {
        const validators = this.lastMessage?.meta?.validate;
        if (this.lastMessage.canBeSkipped && validators.min) {
          delete validators.min;
        }
        return getValidators(validators);
      }
      return [];
    },

    denormalizeChatLevel() {
      const { id: levelId, tasks: levelTasks } = this.chat;

      return levelTasks
        // заполняем таски для чата
        .reduce((accumulator, task) => {
          const { id: taskId, isCompleted: taskCompleted } = task;
          const { content: taskItems } = task;
          taskItems
            // фильтруем таски только разрешенный тип
            .filter(({ type }) => ALLOWED_CHAT_TYPE[type])
            // делаем taskItem из контента таски
            .forEach((taskItem) => {
              const item = generateFlatItem({
                skippedArray: this.skippedTaskItem,
                levelId,
                taskId,
                taskCompleted,
                taskItem,
                isChat: true,
              });
              accumulator.push(item);
              const answer = this.getAnswers(item);
              if (answer) {
                accumulator.push(answer);
              }
            });
          return accumulator;
        }, []);
    },

    chatMessages() {
      return this.denormalizeChatLevel.reduce((accumulator, item, index, array) => {
        const prevItem = array[index - 1];
        return this.pushToMessagesGroup(accumulator, item, prevItem);
      }, []);
    },

    chatCanBeCompleted() {
      if (!this.denormalizeChatLevel) {
        return false;
      }
      return this.denormalizeChatLevel.filter((item) => !item.faked)
        .every((item) => this.isItemCompleted(item));
    },

    loadingInput() {
      return !!(this.loading && this.lastMessage?.type === ENTER_STRING);
    },
  },

  watch: {
    disableInput: {
      handler: 'focusOnChatTextarea',
      immediate: true,
    },

    chatMessages: {
      handler: 'scrollToEnd',
      immediate: true,
    },
  },
  methods: {
    ...mapActions('levels', { setLevelListAction: SET_LEVEL_LIST_ACTION, skipItemAction: SKIP_ITEM_ACTION }),
    ...mapMutations('levels', { mergeLevels: MERGE_LEVELS }),

    isItemCompleted(item) {
      return !!item.firstShown.trim() && ((item.canHasResult && item.result)
        || (item.canBeSkipped && item.isSkipped) || !item.canHasResult);
    },
    focusOnChatTextarea() {
      this.$nextTick(() => {
        this.$refs.chatTextarea.focus();
      });
    },

    scrollToEnd() {
      this.$nextTick(() => {
        const options = this.$vuetify.breakpoint.xsOnly ? { container: this.$refs.Container } : {};
        this.$vuetify.goTo(this.$refs.chatEnd, options);
      });
    },

    async completeTaskIfCan() {
      const readyToComplete = [...new Set(
        this.denormalizeChatLevel
          .filter(({
            taskCompleted, faked, isSkipped, result, canHasResult,
          }) => !taskCompleted && !faked && (isSkipped || result || !canHasResult))
          .map(({ taskId }) => taskId),
      )];
      // eslint-disable-next-line no-restricted-syntax
      for (const taskId of readyToComplete) {
        // eslint-disable-next-line no-await-in-loop
        await this.completeTask(taskId);
      }
    },

    async completeTask(taskId) {
      await snamiApi.taskComplete({
        data: {
          checkOnly: false,
          taskId,
          entityType: TASK,
        },
      });
    },

    async completeChat() {
      try {
        this.startBtnLoading = true;
        await this.completeTaskIfCan();
        const { data: levels } = await snamiApi.levelListV2Get();
        this.setLevelListAction(levels);
        this.$router.push({ name: this.Names.R_APP_LEVELS });
      } catch (e) {
        console.warn(e);
        // no-error
      } finally {
        this.startBtnLoading = false;
      }
    },

    skip(id) {
      this.answer = '';
      this.skipItemAction(id);
    },

    async submit({ answer, message = this.lastMessage }) {
      if (message.type === ENTER_STRING && message.canBeSkipped && !answer) {
        this.skip(message.id);
        return;
      }
      try {
        this.loading = message.id;
        const data = getResultRequest(message, answer);
        const { data: result } = await snamiApi.taskResultUpdate({ data });
        if (message.type === ENTER_STRING) {
          this.answer = '';
        }
        this.mergeLevels(result);
      } catch (e) {
        const dataError = e.response?.data?.error;
        if (dataError) {
          this.$set(this.errors, message.id, dataError);
        }
      } finally {
        this.loading = null;
      }
    },

    async showed({
      id: contentTaskId, taskId, faked,
    }) {
      if (!faked) {
        const { data: result } = await snamiApi.setShown({
          data: {
            contentTaskId,
            entityType: 'TASK',
            taskId,
          },
        });
        this.mergeLevels(result);
      }
    },

    pushToMessagesGroup(accumulator, message, prevMessage = null) {
      if (!prevMessage) {
        accumulator.push({
          id: message.id,
          isUser: message.isUser,
          messages: [message],
        });
      }
      if (prevMessage && prevMessage.firstShown.trim() && ((prevMessage.canHasResult && prevMessage.result)
        || (prevMessage.canBeSkipped && prevMessage.isSkipped) || !prevMessage.canHasResult)) {
        if (prevMessage.isUser === message.isUser) {
          accumulator[accumulator.length - 1].messages.push(message);
        } else {
          accumulator.push({
            id: message.id,
            isUser: message.isUser,
            messages: [message],
          });
        }
      }
      return accumulator;
    },

    getAnswers({
      id, type, result, meta, isSkipped,
    }) {
      if (!result) {
        return false;
      }

      const message = {
        id: -id,
        isUser: true,
        faked: true,
        isSkipped,
        type,
        firstShown: this.$dayjs().toString(),
      };

      switch (type) {
        case ENTER_STRING: {
          const { enterString: { value } } = result;
          message.meta = { question: value };
          break;
        }
        case ADD_PHOTO: {
          const { file } = meta;
          message.meta = file;
          break;
        }
        default:
          break;
      }
      if (message.meta) {
        return message;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.chat-mobile {
    position: fixed;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 56px;
    overflow: hidden;
    overflow-y: auto;
  }
  .chat-content {
    flex: 1 0 auto;
    width: 100%;
    max-width: 1128px;
  }

  .chat-input {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #fff;
    position: sticky;
    bottom: 0;
    padding: 0 12px;
  }
}
</style>
