const getImageRect = (src) => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = () => resolve({
    width: img.width,
    height: img.height,
  });
  img.onerror = (error) => reject(error);
  img.src = src;
});

export default getImageRect;
